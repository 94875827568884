import React, { useState } from 'react'
import { navigate } from 'gatsby'
import Img from 'gatsby-image'

const ClickListItem = ({ title, description, linkToPlay, to, previewImage, gif, publishedDate, isSmallImages = false, children, className = 'item' }) => {

    const onClick = () => {
        if (linkToPlay) {
            window.open(linkToPlay)
        }
        if (to) {
            navigate(to)
        }
    }

    const [ playGif, setPlayGif ] = useState(false)

    const onToggle = () => {
        if (gif) {
            setPlayGif(!playGif)
        }
    }

    const getClass = (name) => (
        name + (isSmallImages ? '--small' : '')
    )

    if (title || previewImage) {
        return (
            <div className={className} onClick={onClick} >
            
                <div className={getClass('item__row')}>
                    { previewImage && (
                        <div className={getClass('item__image-container')} onClick={onToggle} >
                            { 
                                !playGif && (<Img className={getClass('item__image')} fluid={previewImage.fluid} />) 
                            }
                            { 
                                playGif && (<img className={getClass('item__image')} src={gif} alt="loading..." />) 
                            }
                        </div>
                    )}
                    { title && (
                        <div className='item__text' >
                            <h2>{title}</h2>
                            <p>{description}</p>
                        </div>
                    )}
                </div>
                
                { publishedDate && (
                    <div className={getClass('item__row--between')}>
                        <p className='item__meta'>Published: {publishedDate} </p>
                        <button >Open in new tab</button>
                    </div>
                )}
            </div>
        )
    } else {
        return (
            <div className={className} onClick={onClick}>
                {children}
            </div>
        )
    }
}

export default ClickListItem