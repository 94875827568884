import React from 'react'
import Bounce from 'react-reveal/Bounce'

import Layout from '../components/layout'
import Head from '../components/head'
import ClickListItem from '../components/clickListItem'
import styles from './index.module.scss'

const IndexPage = () => {
    return (
        <Layout>
            <Head title="Home" />
            <h3>Hello Internet!</h3>
            <p>Take a look at some of the stuff I've done.</p>

            <div className={styles.container}>
                <Bounce>
                    <ClickListItem className={styles.item} to='/games'>
                        <h2>Game Projects</h2>
                    </ClickListItem>
                </Bounce>
                <Bounce delay={100} >
                    <ClickListItem className={styles.item} to='/tools'>
                        <h2>Tools</h2>
                    </ClickListItem>
                </Bounce>
                <Bounce delay={200} >
                    <ClickListItem className={styles.item} to='/contact'>
                        <h2>Social Platforms</h2>
                    </ClickListItem>
                </Bounce>
            </div>
        </Layout>
    )
}

export default IndexPage
